// frontend/src/App.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-creative-cursor/dist/styles.css";
import "./../App.css";

// import Opening from "./pages/Opening.js";
const Other1 = () => {
    return (
        <div className="blue-bg">

        </div>
    );
};

export default Other1;
